<template>
  <v-card class="mb-15">
    <v-card-title class="mb-10">
      <v-chip
        v-if="isSingleCampaign"
        :class="[titleColor, titleAccent]"
        class="white--text px-5 mr-5"
        >{{ performancePercentage }}</v-chip
      >
      <span :class="`${titleColor}--text text--${titleAccent}`">{{
        campaign.meta.name
      }}</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="sortedTrucksByLevel"
      :search="search"
      @click:row="$emit('truckClick', trucksObj[$event.id])"
      disable-pagination
      hide-default-footer
      class="table-campaign"
    >
      <template v-slot:item.last_activity="{ item }">
        <span v-if="item.last_activity" :title="item.last_activity">{{
          item.last_activity | moment("MMM D, YYYY")
        }}</span>
      </template>
      <template v-slot:item.no_of_issues="{ item }">
        <v-avatar
          :color="item.level === 'ERROR' ? 'red accent-2' : 'insightorange'"
          class="white--text"
          size="32"
          v-if="item.no_of_issues"
        >
          {{ item.no_of_issues }}
        </v-avatar>
        <v-avatar color="#E5E5E5" class="white--text" v-else size="32">
        </v-avatar>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    campaign: Object,
    isSingleCampaign: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "",
          value: "no_of_issues",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Company", value: "company" },
        { text: "Primary Contact", value: "contact" },
        { text: "Plate or Truck VIN", value: "vin" },
        { text: "Device ID", value: "device_id" },
        { text: "Last Activity", value: "last_activity" },
        { text: "Errors", value: "errors", align: " d-none" },
      ],
    };
  },
  computed: {
    titleColor() {
      const levelColor = {
        ERROR: "red",
        WARNING: "insightorange",
        NORMAL: "green",
      };
      return `${levelColor[this.level]}`;
    },
    titleAccent() {
      const levelAccent = {
        ERROR: "accent-2",
        NORMAL: "lighten-1",
      };
      return `${levelAccent[this.level]}`;
    },
    trucksObj() {
      return this.campaign.trucks.reduce((trucks, truck) => {
        trucks[truck.meta.id] = truck;
        return trucks;
      }, {});
    },
    trucks() {
      return this.campaign.trucks
        .map((truck) => ({
          id: truck.meta.id,
          name: `${truck.meta.id} ${truck.meta.nickname}`,
          company: truck.meta.company,
          contact: truck.meta.contact_mobile || truck.meta.contact_office,
          vin: truck.meta.plate || truck.meta.id,
          device_id: truck.device && truck.device.device_id,
          last_activity: truck.meta.last_activity,
          no_of_issues: truck.warnings.length,
          no_of_errors: truck.warnings.filter((w) => w.severity === "Critical")
            .length,
          no_of_warnings: truck.warnings.filter((w) => w.severity === "Warning")
            .length,
          level:
            truck.warnings.length < 1
              ? "NORMAL"
              : truck.warnings.some(
                  (warning) => warning.severity === "Critical"
                )
              ? "ERROR"
              : "WARNING",
          errors: truck.warnings.map((warn) => warn.text).join(". "),
        }))
        .sort((t1, t2) => t2.no_of_issues - t1.no_of_issues);
    },
    normalTrucks() {
      return this.trucks.filter((t) => t.level === "NORMAL") || [];
    },
    errorTrucks() {
      return this.trucks.filter((t) => t.level === "ERROR") || [];
    },
    warningTrucks() {
      return this.trucks.filter((t) => t.level === "WARNING") || [];
    },
    sortedTrucksByLevel() {
      return [...this.errorTrucks, ...this.warningTrucks, ...this.normalTrucks];
    },
    performancePercentage() {
      return Math.round(
        ((this.campaign.meta.total_trucks -
          this.errorTrucks.length -
          this.warningTrucks.length / 2) /
          this.campaign.meta.total_trucks) *
          100
      );
    },
    level() {
      return this.errorTrucks.length > 0
        ? "ERROR"
        : this.warningTrucks.length > 0
        ? "WARNING"
        : "NORMAL";
    },
  },
  mounted() {
    this.$emit("loaded", {
      normalTrucks: this.normalTrucks.length,
      errorTrucks: this.errorTrucks.length,
      warningTrucks: this.warningTrucks.length,
      errorMessages: this.trucks.reduce(
        (sum, curr) => sum + curr.no_of_errors,
        0
      ),
      warningMessages: this.trucks.reduce(
        (sum, curr) => sum + curr.no_of_warnings,
        0
      ),
    });
  },
};
</script>

<style>
.table-campaign tr > td {
  border-bottom: none !important;
}

.table-campaign th,
.table-campaign td {
  font-size: larger !important;
}
</style>
